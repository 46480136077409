



  .punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}
.large-selected{
font-weight: 900;
}
.label-wrapper {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;

  .label-badge {
    font-size: 0.7rem;
    background: #fff;
    color: black;
    border-radius: 25px;
    padding: 0 5px;
  }
}
.small-badge{
  position: absolute;
  top:0;
  right:10px;
  font-size: 0.8rem;
  background: #fff;
  color: black;
  border-radius: 25px;
  padding: 0 5px;
}

.qr-button{
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #007893;
  color: white;
  text-align: center;
  overflow: hidden;
  bottom: 20px;
  right:20px;
}


.scan-controls{
  display: none;
}

